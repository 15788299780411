import React from "react";
import {
  Button,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
//import Skeleton from "@material-ui/lab/Skeleton";
import MUIDataTable from "mui-datatables";
import "moment/locale/es";
import moment from "moment";
moment.locale("es");

const styles = (theme) => ({
  paper: {
    //height: "85vh",
    width: "100%",
    padding: "%",
  },
  candidate: {
    width: "100%",
    minHeight: "200px",
    padding: "5px",
  },
  AddCandidate: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(99, 159, 191,0.2)",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(30, 160, 230)",
    "&:hover": {
      backgroundColor: "rgb(99, 159, 191,0.5)",
    },
  },
  ImgPlaceholder: {
    width: "100%",
    height: "185px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px",
  },
  Remp: {
    width: "100%",
    marginTop: "5px",
    display: "flex",
    justifyContent: "end",
    marginBottom: "10px",
  },
  input: {
    display: "none",
  },
  ImgProp: {
    borderRadius: "8px",
    maxWidth: "100%",
    maxHeight: "185px",
    objectFit: "contain",
    marginTop: "10px",
  },
  Text: {
    width: "100%",
    marginTop: "20px",
  },
  GridDialog: {
    //marginLeft: "10px",
    padding: "1%",
  },
  Select: {
    maxWidth: "100%",
  },
  full: { width: "100%", marginTop: "20px", marginBottom: "20px" },
  Skeleton: {
    width: "100%",
    height: "100%",
    borderRadius: "10px 10px 10px 10px",
  },
  TableArea: {
    marginTop: "12px",
  },
});

class addCandidateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = { List: [] };
  }
  componentDidMount = async () => {
    const prune = this.props.List.map((x) => {
      return [
        x.User.UserProfile.name + x.User.UserProfile.lastNames,
        x.User.UserProfile.sex,
        x.User.UserProfile.city,
        x.User.UserProfile.address,
        x.User.UserProfile.dateOfBirth,
        x.User.UserProfile.CivilStateId,
        x.User.UserProfile.ScholarshipId,
        x.User.UserProfile.mobilePhone,
        x.User.UserProfile.affiliationDate,
      ];
    });
    this.setState({ List: prune });
  };

  render() {
    const columns = [
      "Nombre",
      "Sexo",
      "Municipio",
      "Dirección",
      "Fecha de nacimiento",
      "Estado civil",
      "Escolaridad",
      "Teléfono",
      "Fecha de Afiliación",
    ];

    const options = {
      textLabels: {
        body: {
          noMatch: this.state.List.length > 0 ?  "Estableciendo conexión" : 'Sin coincidencias ',
          toolTip: "Ordenar",
          columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        },
        pagination: {
          next: "Siguiente pagina",
          previous: "Pagina anterior",
          rowsPerPage: "Columnas por pagina:",
          displayRows: "de",
        },
        toolbar: {
          search: "Buscar",
          downloadCsv: "Descargar CSV",
          print: "Imprimir",
          viewColumns: "Ver columnas",
          filterTable: "Filtrar tablas",
        },
        filter: {
          all: "Todos",
          title: "FILTROS",
          reset: "RESET",
        },
        viewColumns: {
          title: "Mostrar Columnas",
          titleAria: "Mostrar/Ocultar Columnas de la tabla",
        },
        selectedRows: {
          text: "fila(s) seleccionadas",
          delete: "Borrar",
          deleteAria: "Borrar Filas seleccionadas",
        },
      },
      filterType: "textField",
      page: 0,
      selectableRows: "none",
      print: false,
      rowsPerPage: 10,
      rowsPerPageOptions: [10, 15, 50, 100],
    };
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.DialogOpen}
        onClose={this.props.DialogClose}
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">Nuevo Evento</DialogTitle>
        <DialogContent className={classes.paper}>
          <div className={classes.paper}>
            {this.props.List.length > 0 ? (
              <MUIDataTable
                title={"Resultado"}
                data={this.state.List}
                columns={columns}
                options={options}
                selectableRows="none"
              />
            ) : (
              <Paper>
                <p>Reporte vació</p>
              </Paper>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.DialogClose} color="secondary">
            <CloseIcon />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(addCandidateDialog);
