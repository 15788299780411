import React, { useState } from "react";
import {
  withStyles,
  Paper,
  Grid,
  Typography,
  Card,
  CardActionArea,
  CardContent,
} from "@material-ui/core";
import DialogContainer from "./DialogContainer";
import IndustrialParks from "./ReportsComponents/industrialParks";
import TechnicalInformation from "./ReportsComponents/TechnicalDetailsSingular";
import AllTechnicalInformation from "./ReportsComponents/AllTechnicalDetails";
import SingularCompanyState from "./ReportsComponents/SingularCompanyState";
import SmallStatistics from "./ReportsComponents/SmallStatistics";
import AffiliationFilesReport from "./ReportsComponents/AffiliationFilesReport";
import CompanyMoms from "./ReportsComponents/CompanyMoms";
import AllMoms from "./ReportsComponents/AllMoms";
import AllPadrones from "./ReportsComponents/Allpadrones";
import ThreeMonthReport from "./ReportsComponents/ThreeMonthReport";
import AllSons from "./ReportsComponents/AllSons";
import AdvisorSons from "./ReportsComponents/AdvisorSons";
import AdvisorMoms from "./ReportsComponents/AdvisorMoms";
const Classes = (theme) => ({
  Paper: {
    width: "100%",
    minHeight: "20vh",
    padding: "12px",
  },
  title: {
    width: "100%",
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "#ad1d21",
    color: "rgb(247, 244, 244)",
    padding: "8px",
    fontWeight: "bold",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
});

function ReportsIndex({ classes }) {
  const reportsList = [
    {
      name: "Reporte de parques Industriales",
      description: "Información básica de salarios y cantidad de afiliados",
      number: 0,
      component: <IndustrialParks />,
    },
    {
      name: "Reporte Histórico de Fichas técnicas por compañía",
      description: "Todas las fichas técnicas por compañía",
      number: 1,
      component: <TechnicalInformation />,
    },
    {
      name: "Reporte Fichas técnicas de todas las compañías",
      description: "Lista todas las fichas técnicas mas actuales",
      number: 2,
      component: <AllTechnicalInformation />,
    },
    {
      name: "Reporte Ficha técnica actual por compañía",
      description: "La ficha técnica mas actual de una compañía",
      number: 3,
      component: <SingularCompanyState />,
    },
    {
      name: "Reporte General de estadística todas las compañías",
      description: " Información de varios valores como escolaridades",
      number: 4,
      component: <SmallStatistics />,
    },
    {
      name: "Reporte histórico de afiliación",
      description: "lista todas las entradas y salidas de los padrones de una compañía por fecha",
      number: 5,
      component: <AffiliationFilesReport />,
    },
    {
      name: "Reporte detallado de madres por asesor",
      description: "Lista de las madres de un Asesor",
      number: 8,
      component: <AdvisorMoms />,
    },
    {
      name: "Reporte de madres existentes por Compañía",
      description: "Lista de las madres de una compañía",
      number: 9,
      component: <CompanyMoms />,
    },
    {
      name: "Reporte de mamas existentes totales",
      description: "Todas las mamas del sistema mostrando de que empresa son",
      number: 10,
      component: <AllMoms />,
    },
    {
      name: "Reporte de historial totales de padrones",
      description: "Detalles del ultimo padrón subido de cada empresa",
      number: 11,
      component: <AllPadrones />,
    },
    {
      name: "Reporte con afiliados de mas de 3 meses",
      description: "Todos los afiliados que tengan mas de 3 meses registrados en el sistema",
      number: 12,
      component: <ThreeMonthReport />,
    },
    {
      name: "Hijos por asesor",
      description: "Todos los hijos que estan dentro de un asesor",
      number: 13,
      component: <AdvisorSons />,
    },
    {
      name: "Hijos totales",
      description: "Todos los hijos del sistema",
      number: 14,
      component: <AllSons />,
    },
  ];
  const [showDialog, SetShowDialog] = useState(false);
  const [ReportInfo, SetReportInfo] = useState({
    name: "default",
    description: "default",
    number: 0,
    component: "",
  });
  return (
    <>
      <Typography className={classes.title} align="center">
        Listado de Reportes{" "}
      </Typography>
      <Paper className={classes.Paper}>
        <Grid md={12} container spacing={2} item>
          {reportsList.map((report) => {
            return (
              <Grid item md={4} key={report.number}>
                <Typography className={classes.title}>{report.name}</Typography>
                <Card className={classes.root} variant="outlined">
                  <CardActionArea
                    onClick={() => {
                      SetReportInfo(report, SetShowDialog(true));
                    }}
                  >
                    <CardContent>
                      <Typography variant="body2" component="p">
                        {report.description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Paper>
      {showDialog && (
        <DialogContainer
          DialogOpen={showDialog}
          DialogClose={() => SetShowDialog(false)}
          name={ReportInfo.name}
        >
          {ReportInfo.component}
        </DialogContainer>
      )}
    </>
  );
}

export default withStyles(Classes)(ReportsIndex);
