import React from "react";
import {
  withStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  AppBar,
  Toolbar,
  IconButton,
} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import Api from "../../../API/WeeklyFeeds";
import toastType from "../../../API/CallUtils/tostyNotification";
import CloseIcon from "@material-ui/icons/Close";
import MUIDataTable from "mui-datatables";
import AddBoxIcon from "@material-ui/icons/Add";
import DialogAddpayment from "./DialogAddpayment";
import "moment/locale/es";
import moment from "moment";
moment.locale("es");
const styles = (theme) => ({
  paper: {
    width: "100%",
    minHeight: "100px",
    minWidth: "500px",
    marginTop: "20px",
  },
  Title: {
    width: "90%",
  },
  CloseBtn: {
    //width: "10%",
  },
  AddCandidate: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(99, 159, 191,0.2)",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(30, 160, 230)",
    "&:hover": {
      backgroundColor: "rgb(99, 159, 191,0.5)",
    },
  },
  Margin: {
    marginTop: "1%",
    padding: "5px",
  },
  text: {
    width: "80%",
  },
});
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class addCandidateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Select: null,
      Oncreate: false,
      name: "",
      formId: "",
      companies: [],
      id: "",
      DialogAdd: false,
      idPayment: "",
    };
  }
  separator = (numb) => {
    var str = numb.toString().split(".");
    str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return str.join(".");
  };
  componentDidMount = async () => {
    this.fetchlist();
  };
  fetchlist = async () => {
    try {
      this.setState({
        onLoad: true,
      });

      const Deli = await Api.Show(this.props.EventId);

      if (Deli.status === "success") {

        this.setState({
          data: Deli.data.reportDetails.map((obj) => {
            return [
              obj.Company.name,
              obj.padronDate ? moment(obj.padronDate).format("l") : "",
              obj.workers,
              obj.meanSalary ? this.separator(Math.floor(obj.meanSalary)) : "",
              obj.dayPayroll ? this.separator(Math.floor(obj.dayPayroll)) : "",
              obj.weeklyPayroll
                ? this.separator(Math.floor(obj.weeklyPayroll))
                : "",
              obj.percent2 ? this.separator(Math.floor(obj.percent2)) : "",
              obj.percent75 ? this.separator(Math.floor(obj.percent75)) : "",
              obj.percent25 ? this.separator(Math.floor(obj.percent25)) : "",
              obj.isPayed ? "Completado" : "Pendiente",
              obj.depositedAmount
                ? this.separator(Math.floor(obj.depositedAmount))
                : "0",
              <Button
                color="primary"
                variant="contained"
                className={this.props.addBTN}
                onClick={this.Dialog.bind(this, "DialogAdd", obj.id)}
              >
                <AddBoxIcon />
              </Button>,
            ];
          }),
        });

        this.setState({onLoad:false})
      } else {
        toastType.error("Error");
        this.setState({
          onError: true,
          onLoad: false,
          ErrorCase: "Error en el servidor",
        });
        this.setState({onLoad:false})
      }
    } catch (error) {
      this.setState({onLoad:false})
      toastType.error("Error");
      this.setState({
        onErrorFetch: true,
        onLoad: false,
        ErrorCase: "Error en la llamada de los recursos",
      });
    }
  };
  Refresh = async (a) => {
    await this.fetchlist();
    this.Dialog(a);
  };
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  selectChange = async (name, value) => {
    this.setState({ [name]: value });
  };
  deleteCompanies = (index) => {
    let value = this.state.companies.filter((item, j) => index !== j);
    this.setState({ companies: value });
  };
  multiSelectChange = (name, value) => {
    this.setState({ [name]: [...this.state[name], value] });
  };
  Send = async () => {
    try {
      this.setState({
        Oncreate: true,
      });
      if (
        this.state.name.length > 0 &&
        this.state.formId &&
        this.state.companies.length > 0
      ) {
        const Deli = await Api.Update(this.state);
        console.log(Deli);
        if (Deli.status === "success") {
          this.setState({
            Oncreate: false,
          });
          toastType.success("Se creado con éxito");
          await this.props.Refresh("EditDialog");
        } else {
          this.setState({
            Oncreate: false,
          });
          toastType.error("SSE No fue Posible Eliminar el elemento");
        }
      } else {
        if (!this.state.name.length > 0) {
          toastType.error("No se puede actualizar con nombre vació");
        }
        if (!this.state.formId) {
          toastType.error("No se puede actualizar Sin Formulario");
        }
        if (!this.state.companies.length > 0) {
          toastType.error("No se puede actualizar Sin compañías");
        }
      }
    } catch (e) {
      this.setState({
        Oncreate: false,
      });
      toastType.error("CSE No fue posible Borrar el evento");
      console.log(e);
    }
  };
  Dialog = (a, b) => {
    this.setState({ [a]: !this.state[a], idPayment: b ? b : null });
  };
  render() {
    const { classes } = this.props;
    const columns = [
      "Empresa",
      "Fecha del padrón",
      "Número de trabajadores",
      "Salario promedio",
      "Nomina diaria",
      "Nomina semanal",
      "Aportación Sindical Semanal 2%",
      "75/100 %",
      "25/100 %",
      "Estado del pago",
      "Cantidad Pagada",
      {
        name: "Añadir pago",
        options: {
         download: false
        }
       },
    ];
    const options = {
      textLabels: {
        body: {
          noMatch: this.state.onLoad ? "Cargando datos" : "Sin Información",
          toolTip: "Ordenar",
          columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        },
        pagination: {
          next: "Siguiente pagina",
          previous: "Pagina anterior",
          rowsPerPage: "Columnas por pagina:",
          displayRows: "de",
        },
        toolbar: {
          search: "Buscar",
          downloadCsv: "Descargar CSV",
          print: "Imprimir",
          viewColumns: "Ver columnas",
          filterTable: "Filtrar tablas",
        },
        filter: {
          all: "Todos",
          title: "FILTROS",
          reset: "RESET",
        },
        viewColumns: {
          title: "Mostrar Columnas",
          titleAria: "Mostrar/Ocultar Columnas de la tabla",
        },
        selectedRows: {
          text: "fila(s) seleccionadas",
          delete: "Borrar",
          deleteAria: "Borrar Filas seleccionadas",
        },
      },
      filterType: "textField",
      page: 0,
      selectableRows: "none",
      print: false,
      rowsPerPage: 100,
      rowsPerPageOptions: [10, 50, 100, 200],
      downloadOptions:{
        filename: 'Reporte de Cuotas',
        separator: ','
      }, 
    };
    return (
      <Dialog
        open={this.props.DialogOpen}
        onClose={this.props.DialogClose}
        fullScreen
        TransitionComponent={Transition}
      >
        <DialogTitle id="form-dialog-title">
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.props.DialogClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
        </DialogTitle>
        <DialogContent className={classes.paper}>
          <div className={classes.paper}>
            {" "}
            <MUIDataTable
              title={"Reporte de Cuotas"}
              data={this.state.data}
              columns={columns}
              options={options}
              selectableRows="none"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.DialogClose} color="secondary">
            Cerrar
          </Button>
          <Button onClick={this.Send} color="Primary">
            Guardar
          </Button>
        </DialogActions>
        {this.state.DialogAdd && (
          <DialogAddpayment
            DialogOpen={this.state.DialogAdd}
            DialogClose={this.Dialog.bind(this, "DialogAdd")}
            idPayment={this.state.idPayment}
            Refresh={this.Refresh}
          />
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(addCandidateDialog);
